import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { inspLayoutConstants } from '../globals/inspConstants.js';

import InspHomepage from './InspHomepage/InspHomepage.js';

class InspIndexRedirectHandler extends Component {
  componentWillMount() {
    const { isAuthorized } = this.props;

    if (isAuthorized) {
      const { history } = this.props;
      let nextPage;
      const nextPathFromSessionStorage = sessionStorage.getItem('goto-path');

      if (nextPathFromSessionStorage && nextPathFromSessionStorage !== '/login') {
        nextPage = nextPathFromSessionStorage;
      } else {
        nextPage = inspLayoutConstants.PATH_NAMES.START_PAGE;
      }

      if (nextPathFromSessionStorage) sessionStorage.removeItem('goto-path');

      if (nextPage.startsWith('http://') || nextPage.startsWith('https://')) {
        window.location.href = nextPage;
      } else {
        history.replace(nextPage);
      }
    }
  }

  // This only happens when one of the conditions above is NOT causing a REDIRECT:
  render() {
    return <InspHomepage />;
  }
}

InspIndexRedirectHandler.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func
  })
};

export default connect(state => ({
  isAuthorized: !!state.identity.role
}))(withRouter(InspIndexRedirectHandler));
